import { render, staticRenderFns } from "./questionBank_info_list_set_type_8.vue?vue&type=template&id=578bbaee&scoped=true"
import script from "./questionBank_info_list_set_type_8.vue?vue&type=script&lang=js"
export * from "./questionBank_info_list_set_type_8.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "578bbaee",
  null
  
)

export default component.exports