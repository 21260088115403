<template>
  <div class="questionBank_info_list_set_type_3">
    <!-- 新增||编辑试题 - 排序 -->
    <el-form ref="questionData" :model="questionData" label-width="120px" size="mini" :rules="questionDataRules">
      <el-form-item label="题目内容" prop="questionName" class="form-item" style="width: 100%;">
        <el-input size="small" show-word-limit maxlength="200" type="textarea" resize="none" rows="3" clearable
          placeholder="请输入题目内容" v-model="questionData.questionName">
        </el-input>
      </el-form-item>
      <el-form-item label="配分" prop="score" class="form-item">
        <el-input-number v-model="questionData.score" :precision="1" :step="1" :min="1" :max="100"
          label="请输入配分"></el-input-number>
      </el-form-item>
      <el-form-item prop="tableData" class="form-item">
        <el-table ref="multipleTable" :data="tableData" :header-cell-style="tableHeader" size="small"
          tooltip-effect="dark" stripe style="width: 100%;">
          <el-table-column property="content" label="勾选选项" width="80px" align="center">
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.checked"
                @change="(value) => checkTableData(value, scope.row, scope.$index)"></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column property="content" label="选项内容" min-width="260" align="center">
            <template slot-scope="{ row }">
              <span style="font-size: 14px;display: inline-block;width: 20px;">{{ row.key }}.</span>
              <el-input v-model="row.content" placeholder="请输入选项内容"
                style="display: inline-block;width: calc(100% - 20px);"></el-input>
            </template>
          </el-table-column>
          <el-table-column property="address" label="上传图片" min-width="100" align="center">
            <template slot-scope="{ row }">
              <el-upload :on-change="(res, file) => { return handleAvatarSuccess2(res, file, row) }"
                :before-upload="$beforeAvatarUpload" :http-request="$requestMine" :show-file-list="false"
                class="img-el-upload" action accept="image/png, image/gif, image/jpg, image/jpeg">
                <el-image :src="row.img ||
                  require('@/assets/develop.png')
                  " fit="contain" class="imgCenter"></el-image>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column property="address" label="操作" align="center" width="120">
            <template slot-scope="scope">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="delRow(scope.$index)">删除选项</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="success" icon="el-icon-plus" size="mini" @click="addRow" style="margin-top: 10px;"
          :disabled="tableData.length > 20">添加选项</el-button>
        <span class="correctSequenceItems">正确答案顺序：<span>{{ questionData.standardAnswer }}</span></span>
      </el-form-item>
      <el-form-item class="btnBox">
        <el-button class="bgc-bv" @click="doClose">取消</el-button>
        <el-button class="bgc-bv" @click="getSure">保存</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>
<script>

import List from "@/mixins/List";
export default {
  name: "examinationAffairsManage_questionBank_info_list_set_type_3",
  components: {

  },
  props: {
    // 基本信息
    basicInfo: {
      bankId: "", // 题库id
      itemId: "", // 考核科目id
      subjectId: "", // 科目id
      itemCode: "", // 科目标识
      itemName: "", // 科目名称
      contentId: "", // 考核内容id
      questionId: "", // 考题id(编辑用)
      questionType: "", // 题目类型
    },
  },
  mixins: [List],
  data() {
    return {
      // 题目基础 - 数据
      questionData: {
        questionName: '',// 题目内容
        score: "", // 配分
        standardAnswer: "", // 正确答案
      },
      // 题目基础 - 数据 - 校验
      questionDataRules: {
        questionType: [
          { required: true, message: '请选择题目类型', trigger: 'change' }
        ],
        questionName: [
          { required: true, message: "请输入题目内容", trigger: "blur" },
        ],
        score: [
          { required: true, message: "请输入配分", trigger: "blur" },
        ],
      },
      // 选项列表数据
      tableData: [{
        key: 'A', // 选项
        content: '', // 内容
        img: '', // 图片地址
        imgKey: '', // 图片地址key
        checked: false, // 正确答案
      }],
    };
  },
  computed: {},
  mounted() {
  },
  created() {
    if (this.basicInfo.questionId) {
      this.getSubjectInfo();
    }
  },
  methods: {
    // 获取 - 试题详情
    getSubjectInfo() {
      this.$post("/biz/bank/pa-question-bank/getQuestion", {
        questionId: this.basicInfo.questionId
      }, 3000, true, 10)
        .then((res) => {
          this.questionData = { ...this.questionData, ...res.data };
          delete this.questionData.optionJson;
          this.tableData = JSON.parse(res.data.optionJson);
        })
        .catch((err) => {
          return;
        });
    },
    // 选项列表 - 删除选项
    delRow(index) {
      this.tableData.splice(index, 1)
      this.tableData.forEach((e, i) => {
        e.key = this.optionFM(i)
      })
    },
    // 选项列表 - 添加选项
    addRow(row) {
      this.tableData.push({
        key: this.optionFM(this.tableData.length),
        content: '',
        img: '',
        checked: false,
      })
    },
    // 选项列表 - 勾选 - 处理选项列表数据
    checkTableData(checked, item, index) {
      this.questionData.standardAnswer = [].concat(...this.questionData.standardAnswer) // 字符串转数组
      this.questionData.standardAnswer = this.questionData.standardAnswer.filter(item => item !== ',');
      if (item.checked) {
        this.questionData.standardAnswer.push(item.key)
      } else {
        this.questionData.standardAnswer.forEach((e, i) => {
          if (e == item.key) {
            this.questionData.standardAnswer.splice(i, 1)
          }
        })
      }
      this.questionData.standardAnswer = this.questionData.standardAnswer.toString();
    },
    // 选项列表 - 图片上传
    handleAvatarSuccess2(res, file, row) {
      let extension = res.name.substring(
        res.name.lastIndexOf(".") + 1,
        res.name.length
      );
      let formData = new FormData();
      formData.append("file", res.raw);
      formData.append("fileType", extension);
      formData.append("folder", "PRACTICAL_QUESTION_BANK");
      formData.append("isPublic", false);
      const that = this;
      that
        .$Postformat("/sys/file/upload/url", formData, 3000, true, 10)
        .then((result) => {
          that.tableData.forEach((e, i) => {
            if (e.index == row.index) {
              row.img = result.data.fileUrl || "";
              row.imgKey = result.data.fileKey || "";
            }
          })
        })
        .catch((err) => {
          setTimeout(() => {
            that.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    // 取消
    doClose() {
      this.$router.back();
    },
    // 保存
    getSure() {
      this.$refs['questionData'].validate((valid) => {
        if (valid) {
          let type_1 = false;
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].checked) {
              type_1 = true;
            }
          }
          if (!type_1) {
            this.$message.warning('请勾择正确的选项');
            return
          }
          let cData = {
            contentId: this.basicInfo.contentId, // 考核内容id
            questionId: this.basicInfo.questionId, // 考核内容id(有则编辑,无则添加)
            questionType: this.basicInfo.questionType, // 题目类型
            ...this.questionData,
            optionJson: JSON.stringify(this.tableData)
          }
          this.$post("/biz/bank/pa-question-bank/itemQuestionSaveUpdate", cData, 3000, true, 10)
            .then((res) => {
              this.$message.success('操作成功');
              this.$refs['questionData'].resetFields();
              this.tableData = [{
                key: 'A', // 选项
                content: '', // 内容
                img: '', // 图片地址
                imgKey: '', // 图片地址key
                checked: false, // 正确答案
              }];
              // 编辑试题 - 编辑完自动返回上一页
              if (this.basicInfo.questionId) {
                this.doClose();
              }
            })
            .catch((err) => {
              return;
            });
        }
      })
    },
    // 选项顺序替换成字母
    optionFM(msg) {
      return String.fromCharCode('A'.charCodeAt(0) + msg);
    },
    // 选项字母替换成顺序
    optionFN(msg) {
      return msg.charCodeAt(0) - 'A'.charCodeAt(0);
    },
  },
};
</script>
<style lang="less" scoped>
.img-el-upload {
  margin: 0 auto;
  width: 88px;
  height: 88px;
}

/deep/.el-upload {
  width: 100%;
  height: 100%;
}

.up_video {
  width: 80px;

  /deep/ .el-upload {
    border: 0 !important;
  }
}

.upFileUrl {
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  line-height: 20px;

  &:hover {
    cursor: pointer;
    color: #5c6be8;
  }
}

.correctSequenceItems {
  color: #666666;
  margin-left: 20px;

  span {
    color: red;
  }
}
</style>
