<template>
  <div class="pageContol templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">题库内容</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ lastPageData.questionId ? '编辑' : '新增' }}试题</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <p>
            <span v-if="lastPageData.subjectId == '1'">科目一</span>
            <span v-if="lastPageData.subjectId == '2'">科目二</span>
            <span v-if="lastPageData.subjectId == '3'">科目三</span>
            <span v-if="lastPageData.subjectId == '4'">科目四</span>
            科目标识：{{ lastPageData.itemCode }} 科目名称：{{ lastPageData.itemName }}
          </p>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="questionData" :model="questionData" label-width="120px" size="mini"
              :rules="questionDataRules">
              <el-form-item label="题目类型" prop="questionType" class="form-item">
                <el-select v-model="questionData.questionType" placeholder="请选择" style="width: 120px"
                           :disabled="lastPageData.questionId?true:false">
                  <el-option v-for="item in questionTypeList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <questions_1 :basicInfo="{ ...lastPageData, ...questionData }" v-if="questionData.questionType == 10" />
            <questions_5 :basicInfo="{ ...lastPageData, ...questionData }" v-if="questionData.questionType == 20" />
            <questions_2 :basicInfo="{ ...lastPageData, ...questionData }" v-if="questionData.questionType == 30" />
            <questions_3 :basicInfo="{ ...lastPageData, ...questionData }" v-if="questionData.questionType == 90" />
            <questions_4 :basicInfo="{ ...lastPageData, ...questionData }" v-if="questionData.questionType == 95" />
            <questions_6 :basicInfo="{ ...lastPageData, ...questionData }" v-if="questionData.questionType == 100" />
            <questions_7 :basicInfo="{ ...lastPageData, ...questionData }" v-if="questionData.questionType == 101" />
            <questions_8 :basicInfo="{ ...lastPageData, ...questionData }" v-if="questionData.questionType == 102" />
            <questions_9 :basicInfo="{ ...lastPageData, ...questionData }" v-if="questionData.questionType == 103" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 单选题
import questions_1 from "@/views/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_list_set_type_1";
// 判断题
import questions_2 from "@/views/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_list_set_type_2";
// 排序题
import questions_3 from "@/views/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_list_set_type_3";
// 考评题
import questions_4 from "@/views/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_list_set_type_4";
// 多选题
import questions_5 from "@/views/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_list_set_type_5";
// 触电题
import questions_6 from "@/views/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_list_set_type_6";
// 灭火题
import questions_7 from "@/views/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_list_set_type_7";
// 心肺复苏题
import questions_8 from "@/views/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_list_set_type_8";
// 创伤包扎题
import questions_9 from "@/views/practicalAssessmentSystem/examinationAffairsManage/questionBank_info_list_set_type_9";
export default {
  name: "examinationAffairsManage_questionBank_info_list_set",
  components: {
    questions_1,
    questions_2,
    questions_3,
    questions_4,
    questions_5,
    questions_6,
    questions_7,
    questions_8,
    questions_9,
  },
  data() {
    return {
      // 上个页面传过来的数据
      lastPageData: {
        bankId: "", // 题库id
        itemId: "", // 考核科目id
        subjectId: "", // 科目id
        itemCode: "", // 科目标识
        itemName: "", // 科目名称
        contentId: "", // 考核内容id
        questionId: "", // 考题id(编辑用)
      },
      // 基础信息
      questionData: {
        questionType: '10', // 题目类型
      },
      // 基础信息 - 数据校验
      questionDataRules: {
        questionType: [{ required: true, message: '请选择题目类型', trigger: 'change' }],
      },
      // 题目类型 - 下拉数据
      questionTypeList: []
    };
  },
  computed: {},
  mounted() {
  },
  created() {
    this.lastPageData = this.$route.query;
    this.getInvoice_type();
    if(this.lastPageData.questionId){
      this.getSubjectInfo(); 
    }
  },
  methods: {
    // 获取 - 获取字典
    getInvoice_type() {
      // 题目类型
      let arr1 = this.$setDictionary("PA_QUESTION_TYPE", "list");
      for (const key in arr1) {
        this.questionTypeList.push({
          label: arr1[key],
          value: key,
        });
      }
    },
    // 获取 - 试题详情
    getSubjectInfo() {
      this.$post("/biz/bank/pa-question-bank/getQuestion", {
        questionId: this.lastPageData.questionId
      }, 3000, true, 10)
        .then((res) => {
          this.questionData.questionType = res.data.questionType;
        })
        .catch((err) => {
          return;
        });
    }
  },
};
</script>
<style lang="less" scoped>
.form-item {
  width: 220px;
}
</style>
