<template>
  <div class="questionBank_info_list_set_type_7">
    <!-- 新增||编辑试题 - 灭火题 -->
    <el-form ref="questionData" :model="questionData" label-width="120px" size="mini" :rules="questionDataRules">
      <el-form-item label="题目内容" prop="questionName" class="form-item" style="width: 100%;">
        <el-input size="small" show-word-limit maxlength="200" type="textarea" resize="none" rows="3" clearable
          placeholder="请输入题目内容" v-model="questionData.questionName">
        </el-input>
      </el-form-item>
      <el-form-item label="配分" prop="score" class="form-item">
        <el-input-number v-model="questionData.score" :precision="1" :step="1" :min="1" :max="100"
          label="请输入配分"></el-input-number>
      </el-form-item>
      <el-form-item class="btnBox">
        <el-button class="bgc-bv" @click="doClose">取消</el-button>
        <el-button class="bgc-bv" @click="getSure">保存</el-button>
      </el-form-item>
    </el-form>
  </div>

</template>
<script>
import List from "@/mixins/List";

export default {
  name: "examinationAffairsManage_questionBank_info_list_set_type_4",
  components: {

  },
  props: {
    // 基本信息
    basicInfo: {
      bankId: "", // 题库id
      itemId: "", // 考核科目id
      subjectId: "", // 科目id
      itemCode: "", // 科目标识
      itemName: "", // 科目名称
      contentId: "", // 考核内容id
      questionId: "", // 考题id(编辑用)
      questionType: "", // 题目类型
    },
  },
  mixins: [List],

  data() {
    return {
      // 题目基础 - 数据
      questionData: {
        questionName: '',// 题目内容
        score: "20", // 配分
      },
      // 题目基础 - 数据 - 校验
      questionDataRules: {
        questionType: [
          { required: true, message: '请选择题目类型', trigger: 'change' }
        ],
        questionName: [
          { required: true, message: "请输入题目内容", trigger: "blur" },
        ],
        score: [
          { required: true, message: "请输入配分", trigger: "blur" },
        ],
      },
    };
  },
  computed: {},
  mounted() {
  },
  created() {
    if (this.basicInfo.questionId) {
      this.getSubjectInfo();
    }
  },
  methods: {
    // 获取 - 试题详情
    getSubjectInfo() {
      this.$post("/biz/bank/pa-question-bank/getQuestion", {
        questionId: this.basicInfo.questionId
      }, 3000, true, 10)
        .then((res) => {
          this.questionData = { ...this.questionData, ...res.data };
        })
        .catch((err) => {
          return;
        });
    },
    // 取消
    doClose() {
      this.$router.back();
    },
    // 保存
    getSure() {
      this.$refs['questionData'].validate((valid) => {
        if (valid) {
          let cData = {
            contentId: this.basicInfo.contentId, // 考核内容id
            questionId: this.basicInfo.questionId, // 考核内容id(有则编辑,无则添加)
            questionType: this.basicInfo.questionType, // 题目类型
            ...this.questionData,
          }
          this.$post("/biz/bank/pa-question-bank/itemQuestionSaveUpdate", cData, 3000, true, 10)
            .then((res) => {
              this.$message.success('操作成功');
              this.$refs['questionData'].resetFields();
              // 编辑试题 - 编辑完自动返回上一页
              if (this.basicInfo.questionId) {
                this.doClose();
              }
            })
            .catch((err) => {
              return;
            });
        }
      })
    },
  },
};
</script>
<style lang="less" scoped></style>
